<template>
	<div>
		<v-card class="elevation-6 card-login" :loading="loading">
			<v-card-text class="pa-0" v-if="!success">
				<v-row>
					<v-col cols="12" class="px-11 py-5">
						<v-row>
							<v-row justify="center" class="text-center">
								<v-col cols="4" class="d-felx justify-center">
								  <v-img
						        contain
						        max-width="150"
						        :src="require('@/assets/Todo-carta-logo.png')"
						      ></v-img>      			
								</v-col>
							</v-row>
							<v-col cols="12" class="pt-0 px-10">										
								<h3 class="primary--text py-1" v-text="'Cambiar Contraseña'"></h3>
								<base-divider color="orange" dense align="left" max-width="35" space="2" />
								<span class="font-weight-light blue-grey--text" style="font-size: 1rem">Ingrese su nueva contraseña</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="px-10">
								<validation-observer ref="observerPassword">
									<v-form>
										<validation-provider name="Contraseña" vid="password" rules="required" v-slot="{ errors }">
											<v-text-field v-model="form.password" outlined id="password" label="Nueva Contraseña" name="Contraseña" prepend-inner-icon="mdi-lock" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :error-messages="errors[0]" :disabled="loading" @keyup.enter="validatePassword" @click:append="show = !show"/></v-text-field>
										</validation-provider>
										<validation-provider name="Confirmar Contraseña" rules="required|confirmed:password" v-slot="{ errors }">
											<v-text-field v-model="form.confirm" outlined id="password" label="Confirmar Contraseña" name="Confirmar" prepend-inner-icon="mdi-lock" :type="show_new ? 'text' : 'password'" :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'" :error-messages="errors[0]" :disabled="loading" @keyup.enter="login" @click:append="show_new = !show_new"/></v-text-field>
										</validation-provider>
									</v-form>
								</validation-observer>				
							</v-col>
						</v-row>
						<v-row justify="center" class="text-center">
							<v-col cols="8" class="px-10">
								<v-btn block color="primary" @click="validatePassword" :loading="loading">Guardar contraseña</v-btn>		
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-text class="pa-0" v-if="success">
				<v-row>
					<v-col cols="12" class="px-11 py-5">
						<v-row>
							<v-row justify="center" class="text-center">
								<v-col cols="4" class="d-felx justify-center mt-4">
									<div class="v-title__icon_large">
								 		<v-icon size="120" color="success">mdi-check</v-icon>
								 	</div>    			
								</v-col>
							</v-row>
							<v-col cols="12" class="pa-10 text-center">										
								<h2 class="primary--text pb-6" v-text="'¡Cambio de contraseña exitoso!'"></h2>
								<span class="font-weight-light blue-grey--text pt-5" style="font-size: 1.1rem">Inicie sesion con su nueva contraseña</span>
							</v-col>
						</v-row>
						<v-row justify="center" class="text-center">
							<v-col cols="8" class="px-10">
								<v-btn block color="primary" @click="$router.push({name: 'login'})">Inciar Sesión</v-btn>		
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<base-material-snackbar
	      v-model="snackbar.active"
	      type="error"
	      bottom
	      center
	    >
	    	{{ snackbar.text }}
	    </base-material-snackbar>
	</div>
	

</template>
<script>
export default {
	data() {
		return {
			form:{
				confirm: '',
				password: ''
			},
			loading: false,
			snackbar:{
				text:'',
				active: false
			},
			show: false,
			show_new: false,
			success: false,
			message: ''
		}
	},
	methods: {
		validatePassword(){
			this.$refs.observerPassword.validate().then(result =>{
				if(result){
					this.sendPassword();
				}
			});
		},
		async sendPassword(){
			this.loading = true;
			try{
				const { data } = await this.$api.post('api/auth/change/password',{password: this.form.password, code: this.$route.params.code});

				if(data){
					this.success = true;
					this.message = data.message;
				}
			}
			catch(e){
				this.snackbar = {
					text:e.response.data.message,
					active: true
				};
			}
			finally{
				this.loading = false;
			}
		}
	},
}
</script>
<style lang="sass">
.v-title__icon_large .v-icon
	align-self: center
	height: 128px
	min-width: 128px
.v-title__icon_large .v-icon:after
	background: currentColor !important
	border-radius: 50%
	bottom: 0
	content: ""
	left: 0
	opacity: 0.16
	position: absolute
	right: 0
	top: 0
</style>

